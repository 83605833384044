@import "../../theme/variables.scss";
.wallpaper{
    background-image: url("../image/ecranDestination_Asklea.webp");
    background-size: cover;
    background-position-x: center;
}

#message {    
    position: absolute;
    left: 40%;
    width: 20%;
    top: 10%;
    background-color: white;
    color: #eb4545;
    display: none;
    justify-content: space-between;
    padding: 20px;
    margin: auto;
    white-space: break-spaces;
    /* width: 700px; */
    /* height: 308px; */
    box-shadow: 0px 0px 20px rgb(43 39 39 / 42%);
    border-radius: 10px;
    opacity: 1;
}

.main-rectangle{
    display:flex;
    justify-content: space-between;
    margin:auto;
    width: 700px;
    height: 325px;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: -3px -3px 20px #2B27276A;
    border-radius: 10px;
    opacity: 1;
}

.left-title{
    margin: auto;
    width: 160px;
    height: 60px;
    text-align: left;
    font: normal normal bold 50px/61px Helvetica Neue !important;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}

.right-rectangle{
    display : flex;
    flex-direction : column;
    justify-content : space-around;
    align-items: center;
    padding-top:25px;
    padding-bottom: 25px;
    padding-left: 17px;
    padding-right: 13px;
    width: 350px;
    height: 325px;
    background: transparent linear-gradient(308deg, #F2F3F6 0%, #E5E6EC 100%) 0% 0% no-repeat padding-box;
    border-radius: 0px 10px 10px 0px;
    opacity: 1;
}

/* QUESTION */

.question {
    width: 320px;
    height: 62px;
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 18px/23px var(--unnamed-font-family-helvetica-neue);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-000000);
    text-align: left;
    //font: normal normal bold 18px/23px Helvetica Neue;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    /*span{
        font-weight: bold;
    }*/
}

/*SELECT DEPARTMENT*/

.department-select {
    width: 320px;
    height: 50px;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1 !important;
}


/* INPUT VILLE*/

#department-input {
    display: flex;
    opacity: 1;
    width: 320px;
    height: 50px;
    cursor: pointer;
    &.disabled{
        ion-icon{
            &::before, &::after{
                background: #D8D8D9;
            }
        }
    }
    ion-input {
        width: 80%;
        height: 100%;
        text-align: left;
        cursor : pointer;

        input {
            padding-left: 23px !important;
            font: var(--unnamed-font-style-normal) medium var(--unnamed-font-weight-normal) var(--unnamed-font-size-16)/18px var(--unnamed-font-family-helvetica-neue);
            letter-spacing: var(--unnamed-character-spacing-0) !important;
            text-align: left !important;
            opacity: 1 !important;
            cursor : pointer;
            color: black
        }
    }

    /*ion-icon {
        position: relative;
        content: url('../image/ionic-ios-arrow-down.svg');
        width: 17px;
        height: 10px;
        opacity: 1;
        padding-right: 18px;
        margin: auto;
        color: #575F6B;
    }*/
    ion-icon{
        position: relative;
        display: inline-block;
        width: 53px;
        height: 100%;
        padding-left: 17px;
        &:before, &:after {
            position: absolute;
            top: calc(50% - 1px);
            right: auto;
            width: 10px;
            height: 3px;
            padding-right: 11px;
            margin: auto;
            background: #575F6B;
            content: "";
            // transform-origin: 50% 50%;
            will-change: transform;
            transition: all .2s ease;
        }
        &:before {
            transform: 
            rotate(45deg) 
            translate(1px, -1px);
        }
        &:after {
            transform: 
            rotate(-45deg) 
            translate(-3px,-3px);
        }
        
        &.active{
            &:before, &:after {
                // background: $brandGreen;
            }
            &:before {
                transform: 
                rotate(45deg)
                translate(-2px,2px);
            }
            &:after {
                transform: 
                rotate(-45deg)
                translate(2px,2px);
            }
            
        }
        
    }
}

.hasFocus {
    outline: 1px solid #1999B1 ;
    border-radius: 10px;
}

#city-input{
    width: 320px;
    height: 50px;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    //background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #F1F1F1;
    border-radius: 10px;
    opacity: 1;
    display: flex;
    position: relative;
    ion-input {
        width: 80%;
        height: 100%;
        text-align: left;
        
        input {
            padding-left: 23px !important;
            font: var(--unnamed-font-style-normal) medium var(--unnamed-font-weight-normal) var(--unnamed-font-size-16)/18px var(--unnamed-font-family-helvetica-neue);
            letter-spacing: var(--unnamed-character-spacing-0) !important;
            text-align: left !important;
            font: normal normal medium 16px/18px Helvetica Neue;
            letter-spacing: 0px !important;
            opacity: 1 !important;
            z-index: 9;
        }
    }

    ion-input.input-disabled{
        --placeholder-opacity: 0.3;
    }
    ion-input.input-enabled{
        --placeholder-opacity: 1;
        --placeholder-color: #575F6B !important;
        color : black;
    }

    ion-icon {
        position: relative;
        width : 21px;
        height: 21px;
        padding-right: 10px;
        margin: auto;
    }
    ion-icon.icon-disabled {
        color: #D8D8D9;
        }

    ion-icon.icon-enabled {
        color: #575F6B;
        }
}

/*DROPDOWN*/

ion-list{
    background: transparent 0% 0% no-repeat padding-box;
    --background-color : transparent;
    border: 0px solid #F1F1F1;
    border-radius: 10px;
    padding : 0px !important;
    &.list-md{
        background: transparent !important;
    }
}

#city-list {
    z-index : 10;
    position : absolute;
    top : 0;
    cursor : pointer;
}

#department-list {
    z-index : 10;
    position : absolute;
    top : 0;
    cursor : pointer;
}

ion-item {
    width: 320px;
    height: 50px;
    --background : #FFFFFF;
    --color: #000000;
    --background-hover : #1999B1 !important;
}
ion-item:hover{
    color: #FFFFFF;
}

ion-item::part(native) {
    width: 320px;
    height: 50px;
}

/* BOUTON VALIDER */

ion-button {
    width: 99px;
    height: 50px;
    --background: transparent !important;
    --box-shadow : 0;
    text-align: center;
    border-radius: 10px;
    opacity: 1;
}

.ionic-button-disabled {
    border: 2px solid #D8D8D9;
}

.ionic-button-enabled {
    border: 2px solid #1999B1;
}

#button-label{
    width: 52px;
    height: 19px;
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-16)/var(--unnamed-line-spacing-19) var(--unnamed-font-family-helvetica-neue);
    letter-spacing: var(--unnamed-character-spacing-0);
    text-align: left;
    font: normal normal bold 16px/19px Helvetica Neue;
    letter-spacing: 0px;
    text-transform: none;
    opacity: 1;
}

.button-label-disabled {
    color: #D8D8D9;
}

.button-label-enabled {
    color: #1999B1;
}

.close {
    z-index: 10;
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 40px;
    height: 100%;
    font-size: 0;
    cursor: pointer;
    &:before, &:after {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 2px;
      height: 17px;
      background-color: #575F6B;
      transform: rotate(45deg) translate(-50%, -50%);
      transform-origin: top left;
      transition: all 420ms;
      content: '';
    }
    &:after {
      transform: rotate(-45deg) translate(-50%, -50%);
    }
    &:hover:before, &:hover:after {
      background-color: #575F6B;
    }
  }

  .loader-container{
    background: rgb(71 71 71 / 64%);
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    z-index: 999;
    .loader {
      position: absolute;
      display: inline-block;
      width: 50px;
      height: 50px;
      border: 3px solid rgba(255,255,255,.3);
      border-radius: 50%;
      border-top-color: #fff;
      animation: spin 1s ease-in-out infinite ;
      -webkit-animation: spin 1s ease-in-out infinite;
      @keyframes spin {
        to { -webkit-transform: rotate(360deg); }
      }
      @-webkit-keyframes spin {
        to { -webkit-transform: rotate(360deg); }
      }
    }
  }

 
//Mobile
  @media only screen and (max-width: 600px) {
    .main-rectangle{
        display:flex;
        justify-content: space-between;
        flex-direction: column;
        margin:auto;
        width: 80%;
        height: 50%;
        background: transparent 0% 0% no-repeat padding-box;
        box-shadow: none;
        border-radius: 10px;
        opacity: 1;
    }

    .right-rectangle{
        display : flex;
        flex-direction : column;
        justify-content : space-around;
        align-items: center;
        padding-top:25px;
        padding-left: 17px;
        padding-right: 13px;
        width: 100%;
        max-width: 400px;
        margin:auto;
        height: 80%;
        background: transparent linear-gradient(308deg, #F2F3F6 0%, #E5E6EC 100%) 0% 0% no-repeat padding-box;
        box-shadow: -3px -3px 20px #2B27276A;
        border-radius: 10px;
        opacity: 1;
    }

    .left-title{
        background-color: transparent;
    }

    #message {    
        position: absolute;
        left: 10%;
        width: 80%;
        top: 3%;
        background-color: white;
        border: 1px black solid;
        padding: 10px;
        color: red;
        display: none;
        text-align: center;
    }

    .question {
        width: 100%;
        max-width: 320px;
        height: auto;
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 18px/23px var(--unnamed-font-family-helvetica-neue);

        ion-text {
            --width: 100%;
        }
    }

    .department-select{
        width: 100%;
        max-width: 320px;
    }

    #department-input {
        width: 100%;
        max-width: 320px;
        ion-icon{
            width: 26px;
        }
    }

    #city-input{
        width: 100%;
        max-width: 320px;
    }
}

//Medium
@media only screen and (min-width: 601px)  and (max-width: 1199px){
    #department-input {
        width: 100%;
        max-width: 320px;
        ion-icon{
            width: 26px;
        }
    }
  }